/* DESKTOP */
@media (min-width: 769px) {
  .container {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
  }

  .picture {
    width: 100vw;
    height: 75vh;
  }

  .textContainer {
    width: 56vw;
    padding: 0 5rem;
    font-size: 1.75rem;
    line-height: 2.5rem;
  }

  .root > div {
    padding: 2rem 6rem 2rem 2rem;
  }
}

/* MOBILE */
@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1vh;
  }

  .textContainer {
    width: 95vw;
    text-align: center;
    align-items: center;
    margin-top: 1vh;
    padding: 1.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.textContainer {
  background-color: var(--main-color);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dramaticText {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 1rem;
}

.bold {
  font-weight: bold;
}
