.root {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
  transition: filter 0.3s ease;
}

.root:hover {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.title {
  opacity: 0.7;
  background-color: white;
  color: black;
  text-align: center;
}

.description {
  opacity: 0.8;
  text-align: center;
  color: black;
  background-color: white;
}
