/* DESKTOP */
@media (min-width: 769px) {
  .fourPillarsVisual {
    width: 100vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0.5rem;
    padding: 0 0.5rem;
  }

  .pictureSize {
    height: 65vh;
    width: 100%;
  }
}

/******************************************************************/

/* MOBILE */
@media (max-width: 768px) {
  .fourPillarsVisual {
    width: 100vw;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 1vh;
    padding: 4vh 2vw 0 2vw;
  }

  .pictureSize {
    height: 50vh;
    width: 100%;
  }
}

/******************************************************************/

.fourPillarTitle {
  font-size: 2.25rem;
  font-weight: bold;
  padding: 1.5rem 1rem;
  margin-bottom: 4rem;
}

.fourPillarDescription {
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom: 4rem;
}
