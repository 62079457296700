/* DESKTOP */
@media (min-width: 769px) {
  .root {
    height: 50vh;
    width: 20vw;
    margin: 0 1vw;
    border-radius: 1rem;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 3rem;
  }

  .description {
    width: 100%;
    margin-top: 4vh;
    color: black;
    background-color: white;
    font-weight: bold;
    padding: 0.75rem 1rem;
    min-height: 4rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
  }
}

/* MOBILE */
@media (max-width: 768px) {
  .root {
    height: 60vh;
    width: 90vw;
    margin: 0 0 2vh 0;
    border-radius: 1rem;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2.5rem;
  }

  .description {
    width: 100%;
    margin-top: 3vh;
    color: black;
    background-color: white;
    font-weight: bold;
    padding: 0.75rem 1rem;
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.5rem;
    line-height: 2rem;
    opacity: 0.8;
  }
}

/******************************************************************/

.pictureFilter {
  border-radius: 1rem;
}
