:root {
  --main-color: #459eac;
  --main-colorPlus: #daedf0;

  --link-blue-color: #00305b;
  --gray-color: #58595b;
  --light-gray-color: #c2c2c3;

  --blue-color: #0082c8;
  --yellow-color: #f4bb27;
  --red-color: #e45455;
  --purple-color: #8b5aa5;

  --base-text: #333e48;
}

* {
  box-sizing: border-box;
  font-weight: 400;
  font-family: 'Muli', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  touch-action: pan-y;
}

.gl-modal,
#gl-widget-modal {
  display: none;
}
