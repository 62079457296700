/* DESKTOP */
@media (min-width: 769px) {
  .root {
    width: 100vw;
    padding: 5rem 6rem 2rem 2rem;
  }

  .titleContainer {
    padding-bottom: 1.5rem;
  }

  .title {
    font-size: 3rem;
    font-weight: bold;
    color: var(--base-text);
  }

  .description {
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: var(--gray-color);
  }
}

/* MOBILE */
@media (max-width: 768px) {
  .root {
    width: 100vw;
    padding: 4vh 6vw;
    text-align: center;
  }

  .titleContainer {
    padding-bottom: 1rem;
  }

  .title {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: bold;
    color: var(--base-text);
  }

  .description {
    font-size: 1.25rem;
    line-height: 2rem;
    color: var(--gray-color);
  }
}

/* ************************************* */
