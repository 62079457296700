/* DESKTOP */
@media (min-width: 769px) {
  .howToGetInvolvedItemsContainer {
    padding: 0 1vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* MOBILE */
@media (max-width: 768px) {
  .howToGetInvolvedItemsContainer {
    padding: 0;
  }
}

/******************************************************************/

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}
