.header {
  width: 100vw;
  display: grid;
  grid-template-columns: 20% 80%;
}

.headerLeft:hover {
  cursor: pointer;
}

.headerLeft:hover a {
  color: white;
}

.mobileHeader {
  width: 100vw;
  height: 4.75rem;
  display: grid;
  grid-template-columns: 88vw 12vw;
  background-color: white;
  box-shadow: 0 1px 4px gray;
}

.mobileMenu {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  box-shadow: 0 1px 4px gray;
}

.mobileMenuIcon {
  padding-top: 3vh;
}

.displayMobileMenu {
  max-height: 50vh;
}
