/* DESKTOP */
@media (min-width: 769px) {
  .heroPictureContainer,
  .heroPictureContainer::before {
    height: 90vh;
  }

  .heroText {
    font-size: 4rem;
    line-height: 4.5rem;
    bottom: 20vh;
    left: 5vw;
  }

  .ourImpactItemContainer {
    width: 100vw;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .ourImpactText {
    padding-top: 2.75rem;
    width: 18rem;
    font-size: 1.25rem;
  }

  .ourImpactItem {
    margin: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

/* MOBILE */
@media (max-width: 768px) {
  .heroPictureContainer,
  .heroPictureContainer::before {
    height: 93vh;
  }

  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .heroText {
    font-size: 2.5rem;
    line-height: 3rem;
    bottom: 0;
    left: 0;
    width: 100vw;
  }

  .ourImpactItemContainer {
    display: flex;
    flex-direction: column;
  }

  .ourImpactText {
    padding-top: 2.75rem;
    width: 75vw;
    font-size: 1.25rem;
  }

  .ourImpactItem {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    text-align: center;
  }
}

/* *********************************** */

.heroPictureContainer {
  width: 100vw;
  position: relative;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.heroPictureContainer::before {
  pointer-events: none;
  content: ' ';
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: white;
  background: url('../../../Images/Hero/HomePage.png') no-repeat top center;
  background-size: cover;
  will-change: transform;
  z-index: -1;
}

.heroText {
  position: absolute;
  background-color: white;
  opacity: 0.8;
}

.blurb {
  padding: 6rem 10rem;
  font-size: 4rem;
  text-align: center;
}

.theirVisionVisual {
  width: 100vw;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourImpactImage {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}

.ourImpactIcon {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  bottom: -2rem;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px gray;
}

.ourImpactType {
  font-weight: 600;
  font-size: 1rem;
  padding-bottom: 0.5rem;
  color: var(--gray-color);
}

.greenBold {
  color: var(--main-color);
  font-weight: bold;
}
