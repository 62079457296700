/* Needed to do page transition */
.page {
  position: absolute;
}

/* PAGE ANIMATIONS */
.pageFadeEnter {
  opacity: 0;
  z-index: 1;
}

.pageFadeEnter.pageFadeEnterActive {
  opacity: 1;
  transition: opacity 1500ms;
}

.pageFadeExit {
  opacity: 1;
  z-index: 1;
}

.pageFadeExit.pageFadeExitActive {
  opacity: 0;
  transition: opacity 1500ms;
}
