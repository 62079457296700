/* DESKTOP */
@media (min-width: 769px) {
  .headerLinks {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 0.5vw;
    font-size: 1.4rem;
  }

  .link > a,
  .link > button > span {
    color: white;
    font-weight: bold;
  }

  .link > a:hover {
    color: white;
    text-decoration: underline;
  }

  .link {
    padding: 0vw 1vw;
  }

  .link > .active {
    color: var(--main-color);
    font-weight: bold;
  }
}

/* MOBILE */
@media (max-width: 768px) {
  .headerLinks {
    font-size: 1.25rem;
    background-color: white;
    text-align: center;
  }

  .link {
    padding: 1.5vh 0;
    border-top: 1px solid var(--light-gray-color);
  }

  .link > a,
  .link > span {
    text-decoration: none;
    color: black;
  }

  .link > a:hover,
  .link > span:hover {
    color: black;
    text-decoration: none;
  }

  .link > .active {
    color: var(--main-color);
    font-weight: bold;
  }
}
