/* Animate the size, outside */
.button:hover,
.button:focus {
  outline: none;
  animation: pulse 0.75s;
  box-shadow: 0 0 0 0.5em rgba(255, 255, 255, 0);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 white;
  }
}

@-moz-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 white;
  }
}

@-webkit-keyframes pulse  /* Safari and Chrome */ {
  0% {
    box-shadow: 0 0 0 0 white;
  }
}

@-o-keyframes pulse  /* Opera */ {
  0% {
    box-shadow: 0 0 0 0 white;
  }
}
@-ms-keyframes pulse  /* IE */ {
  0% {
    box-shadow: 0 0 0 0 white;
  }
}

.button {
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  padding: 0;
  margin: 0;
  align-items: center;
  display: inline-flex;
  width: auto;
  min-width: 4rem;
  justify-content: center;
  border: 3px solid white;
  color: white;
  background-color: transparent;
  border-radius: 0.3125rem;
}

.button > a,
.button > a:hover {
  color: white;
  font-weight: bold;
}

.small {
  font-size: 0.75rem;
  padding: 0.3125rem 0.625rem;
}

.medium {
  font-size: 1.5rem;
  padding: 0.6rem 1rem;
  font-weight: bold;
}

.large {
  font-size: 3rem;
  padding: 1rem 1.5rem;
  border: 4px solid white;
  font-weight: bold;
}

.fullWidth {
  display: block;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.solid {
  background-color: var(--main-color);
  color: white;
  transition: all 0.25s ease-in;
  -webkit-transition: all 0.25s ease-in;
}
