/* ************ DESKTOP ************** */
@media (min-width: 769px) {
  .root {
    margin-left: -1rem;
    display: flex;
    align-items: center;
  }

  .input {
    background-color: var(--main-color);
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    width: 10rem;
    color: white;
  }

  .inputContainer {
    padding: 1.5rem 1.5rem 1.5rem 1.75rem;
    border: thin solid white;
    display: inline-block;
    transform: skew(30deg);
    -webkit-transform: skew(-30deg);
    -moz-transform: skew(-30deg);
    -o-transform: skew(-30deg);
  }

  .inputContainer input {
    outline: none;
    border: none;
    transform: skew(30deg);
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
  }

  .buttonContainer {
    height: 4.5rem;
  }
}

/* ************MOBILE**************** */

@media (max-width: 768px) {
  .root {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input {
    background-color: var(--main-color);
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none;
    width: 5rem;
    color: white;
  }

  .inputContainer {
    margin: 1.25rem 0;
    padding: 1.25rem 1.25rem 1.25rem 1.5rem;
    border: thin solid white;
    display: inline-block;
    width: 20rem;
  }

  .inputContainer input {
    outline: none;
    border: none;
    width: 15rem;
  }

  .buttonContainer {
    margin-left: -1rem;
    margin-top: 1.5rem;
    height: 4rem;
  }
}
/* ************************************ */

.hidden {
  display: none;
}

.inputContainer:focus-within,
.input:focus {
  background-color: white;
  color: black;
}

.input::placeholder {
  color: white;
}

.buttonContainer {
  width: 6rem;
  border: thin solid white;
  padding: 1.5rem 1.5rem 1.5rem 1.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: skew(30deg);
  -webkit-transform: skew(-30deg);
  -moz-transform: skew(-30deg);
  -o-transform: skew(-30deg);
}

.notButton {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
