/* ************ DESKTOP ************** */
@media (min-width: 769px) {
  .root {
    width: 100vw;
    background-color: var(--main-color);
    color: white;
    padding: 0 5rem;
    display: flex;
  }

  .middleAlign {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-size: 2rem;
    padding: 3rem 0;
  }

  .iconsContainer {
    display: flex;
    align-items: center;
  }

  .whiteIcon {
    margin-right: 2rem;
  }
}

/* ************MOBILE**************** */

@media (max-width: 768px) {
  .root {
    width: 100vw;
    height: 100%;
    background-color: var(--main-color);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .middleAlign {
    margin-bottom: 2rem;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .title {
    width: 100%;
    font-size: 2rem;
    line-height: 2.75rem;
    text-align: center;
    padding: 1rem 0;
    max-width: 75vw;
  }

  .iconsContainer {
    display: flex;
    justify-content: space-evenly;
  }
}
/* ************************************ */

.rightAlign {
  display: flex;
  align-items: center;
}

.textCenterAlign {
  text-align: center;
}

.whiteIcon,
.whiteIcon:hover {
  color: white;
}

.emailFormContainer {
  padding-top: 1rem;
}

.iconsContainer {
  padding: 3rem 0;
}

.text {
  font-size: 1.25rem;
  padding-bottom: 0.75rem;
}

.exposureIcon {
  display: flex;
  align-items: center;
}
